var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"elevation":"1"}},[(_vm.showPlayer)?[_c('v-expand-transition',[(_vm.showPlayer)?_c('record-player',{attrs:{"file":_vm.file},on:{"closed":_vm.closedPlayer}}):_vm._e()],1),(_vm.showPlayer)?_c('v-divider'):_vm._e()]:_vm._e(),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pt-2",attrs:{"cols":"12","sm":"2"}},[_c('v-switch',{attrs:{"label":"With Check","inset":""},model:{value:(_vm.onlyWithCheck),callback:function ($$v) {_vm.onlyWithCheck=$$v},expression:"onlyWithCheck"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"rules":[_vm.rules.phone],"prepend-inner-icon":"mdi-numeric-positive-1","outlined":"","dense":"","clearable":"","label":"Search by Number","disabled":_vm.filterpatient != null || _vm.filteremployee != null},on:{"click:clear":_vm.clearFilters},model:{value:(_vm.filternumber),callback:function ($$v) {_vm.filternumber=$$v},expression:"filternumber"}})],1),_c('v-col',[_c('filter-by-clients',{staticClass:"ml-1",attrs:{"disabled":_vm.filteremployee != null ||
            (_vm.filternumber != null && _vm.filternumber != null)},on:{"selectC":function($event){return _vm.filteringPatient($event)}}})],1),(_vm.isSuper || _vm.isAdmin || _vm.isSupervisor)?[_c('v-col',[_c('v-autocomplete',{staticClass:"mx-1",attrs:{"label":"Search by Employees","items":_vm.workersf,"loading":_vm.loadingEmployees,"disabled":_vm.filterpatient != null ||
              (_vm.filternumber != null && _vm.filternumber != ''),"filter":function (item, queryText) {
                return item.fullname
                  .toLowerCase()
                  .includes(queryText.toLowerCase());
              },"item-text":"fullname","item-value":"uuid","autocomplete":"off","clearable":"","hide-details":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.fullname)}}),(data.item.ocupation)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.ocupation.name)}}):_vm._e(),(data.item.phone)?_c('v-list-item-subtitle',{staticClass:"text-capitalize",domProps:{"innerHTML":_vm._s(data.item.phone)}}):_vm._e()],1)]}}],null,false,1535484464),model:{value:(_vm.filteremployee),callback:function ($$v) {_vm.filteremployee=$$v},expression:"filteremployee"}})],1)]:_vm._e(),_c('v-col',{},[_c('ma-date-picker',{attrs:{"label":"From","past":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1),_c('v-col',{staticClass:"mx-2"},[_c('ma-date-picker',{attrs:{"label":"To","past":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),(_vm.validClean)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"primary","elevation":"0","small":"","rounded":"","depressed":"","fab":""},on:{"click":_vm._clearFilters}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-backup-restore")])],1)]}}],null,false,527521509)},[_c('span',[_vm._v("Clear filters")])])]:_vm._e()],2),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading,"text":""},on:{"click":_vm.getCalls}},[_c('v-icon',[_vm._v("mdi-sync")]),_vm._v(" "+_vm._s(_vm.$t("labelUpdate"))+" ")],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.heads,"items":_vm.missedcall,"footer-props":_vm.footerProps,"options":_vm.options,"server-items-length":_vm.totalmiss,"item-key":"index","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"phone-number d-flex"},[(_vm.admin)?[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error"}},[_vm._v("mdi-phone-outgoing")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.from)}}},[_vm._v(" "+_vm._s(item.from)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.from)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])]:[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(item.inbound == 1 ? "mdi-phone-incoming" : "mdi-phone-outgoing"))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.inbound == 1 ? item.from : item.to)}}},[_vm._v(" "+_vm._s(item.inbound == 1 ? item.from : item.to)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.inbound == 1 ? item.from : item.to)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])]],2)]}},{key:"item.to",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"phone-number d-flex"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error"}},[_vm._v("mdi-phone-incoming")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.makeCallV(item.to)}}},[_vm._v(" "+_vm._s(item.to)+" ")]),_c('div',{staticClass:"mx-1"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"info"},on:{"click":function($event){return _vm.copyPhone(item.to)}}},on),[_vm._v("mdi-content-copy")])]}}],null,true)},[_c('span',[_vm._v("Copy Phone")])])],1)])],1)]}},{key:"item.duration",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.prettyTime(item.duration)))])]}},{key:"item.origin",fn:function(ref){
              var item = ref.item;
return [(_vm.admin)?[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.getFromCall(item).origin))])]:[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.getFromCall(item).origin == "Employee" ? "MySelf" : _vm.getFromCall(item).origin)+" ")])]]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [(_vm.admin)?[_c('div',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.getFromCall(item).name == _vm.getName ? "MySelf" : _vm.getFromCall(item).name)+" ")])]:[_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.getFromCall(item).name))])]]}},{key:"item.toname",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.getNameTo(item)))])]}},{key:"item.createAt",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.formatDate(item.createAt)))])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.isSuper)?[_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.setRecordToRemove(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]:void 0]}}],null,true)},[_vm._v(" "+_vm._s(/* From agent */)+" "),_vm._v(" "+_vm._s(/* To agent */)+" "),_vm._v(" "+_vm._s(/* Duration */)+" "),_vm._v(" "+_vm._s(/* Origin */)+" "),_vm._v(" "+_vm._s(/* Name */)+" "),_vm._v(" "+_vm._s(/* Date */)+" "),_vm._v(" "+_vm._s(/* Remove record */)+" "),_c('not-records',{attrs:{"slot":"no-data"},slot:"no-data"})],1),_vm._v(" "+_vm._s(/* Confirm remove */)+" "),_c('v-dialog',{attrs:{"max-width":"280","content-class":"rounded-xl","persistent":_vm.loading},model:{value:(_vm.dialogConfirmRemove),callback:function ($$v) {_vm.dialogConfirmRemove=$$v},expression:"dialogConfirmRemove"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h6 font-weight-regular rounded-xl"},[_vm._v(" "+_vm._s(_vm.$t("dialogTitle"))+" ")]),_c('v-layout',{staticClass:"pa-4",attrs:{"column":""}},[_c('div',{staticClass:"text-body-1 text-truncate d-flex mb-2"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("mdi-phone-outgoing")]),_c('span',{staticClass:"dosis"},[_vm._v(_vm._s(_vm.currentRecord.from))])],1),_c('div',{staticClass:"text-body-1 text-truncate d-flex"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"green"}},[_vm._v("mdi-phone-incoming")]),_c('span',{staticClass:"dosis"},[_vm._v(_vm._s(_vm.cleanPhone(_vm.currentRecord.to)))])],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"success","disabled":_vm.loading,"text":""},on:{"click":function($event){_vm.dialogConfirmRemove = false}}},[_vm._v(" "+_vm._s(_vm.$t("labelCancel"))+" ")]),_c('v-btn',{attrs:{"color":"red","loading":_vm.loading,"text":""},on:{"click":_vm.remove}},[_vm._v(" "+_vm._s(_vm.$t("labelRemove"))+" ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }